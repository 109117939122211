@media (min-width: 992px) and (max-width: 1199px) {
    .menu_area .navbar-brand {
        font-size: 42px;
    }
    .menu_area #nav .nav-link {
        padding: 35px 7.5px;
    }
    .sing-up-button > a {
        color: #fff;
        height: 40px;
        min-width: 152px;
        line-height: 36px;
        font-size: 14px;
    }
    .welcome-thumb {
        width: 50%;
        bottom: -100px;
        right: 50px;
    }
    .video-area {
        height: 550px;
    }
    .app_screenshots_slides {
        padding: 0 4%;
    }
    .header_area.sticky .menu_area #nav .nav-link {
        padding: 23px 7.5px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header_area {
        padding: 0;
    }
    .menu_area .navbar-brand {
        font-size: 48px;
    }
    .header_area.sticky {
        height: 50px;
    }
    .header_area .menu_area #nav .nav-link,
    .header_area.sticky .menu_area #nav .nav-link {
        padding: 7.5px 15px;
    }
    #ca-navbar {
        padding: 30px;
        border-radius: 3px;
        background-color: #5b32b4;
        text-align: left;
        z-index: -3;
    }
    .sing-up-button {
        margin-top: 30px;
        text-align: left;
    }
    .welcome-thumb {
        width: 50%;
        bottom: -100px;
        right: 0;
    }
    .wellcome-heading {
        margin-bottom: 70px;
    }
    .single-special {
        padding: 30px 10px;
    }
    .special_description_area.mt-150 {
        margin-top: 50px;
    }
    .special_description_content > h2 {
        font-size: 30px;
    }
    .video-area {
        height: 350px;
    }
    .section-heading > h2 {
        font-size: 38px;
    }
    .address-text > p,
    .phone-text > p,
    .email-text > p {
        font-size: 16px;
    }
    .footer-text > p {
        margin-bottom: 30px;
    }
    .section-heading {
        margin-bottom: 50px;
    }
    .sing-up-button > a {
        margin-top: 4px;
        display: inline-block;
        border: 2px solid #a883e9;
        height: 40px;
        min-width: 160px;
        line-height: 36px;
        font-size: 14px;
    }
    .counter-area {
        min-width: 50px;
    }
    .counter-area > h3 {
        font-size: 30px;
    }
    .app_screenshots_slides {
        padding: 0;
    }
    .clients-feedback-area .slick-slide.slick-current img {
        width: 90px !important;
        height: 90px !important;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .header_area {
        padding: 0;
    }
    .menu_area .navbar-brand {
        font-size: 48px;
    }
    .header_area.sticky {
        height: 50px;
    }
    .header_area .menu_area #nav .nav-link,
    .header_area.sticky .menu_area #nav .nav-link {
        padding: 5px 15px;
    }
    #ca-navbar {
        padding: 20px;
        border-radius: 3px;
        background-color: #5b32b4;
        text-align: left;
        z-index: -3;
    }
    .sing-up-button {
        margin-top: 30px;
        text-align: left;
    }
    .header_area {
        top: 0;
    }
    .welcome_text .cd-intro > p {
        font-size: 14px;
    }
    .wellcome-heading > h2 {
        font-size: 48px;
    }
    .get-start-area .email {
        margin-bottom: 20px;
        max-width: 100%;
    }
    .welcome-thumb {
        width: 90%;
        bottom: -74px;
        right: 0;
    }
    .logo_area > a > h2 {
        font-size: 40px;
        margin-top: 10px;
    }
    .section-heading > h2 {
        font-size: 32px;
    }
    .single-special {
        margin-bottom: 30px;
    }
    .special_description_area.mt-150 {
        margin-top: 50px;
    }
    .special_description_content > h2 {
        font-size: 30px;
    }
    .app-download-btn:first-child {
        margin-right: 0;
    }
    .app-download-area {
        display: block;
    }
    .app-download-btn {
        margin-bottom: 20px;
    }
    .video-area {
        border: 10px solid #fff;
        border-radius: 20px;
        height: 250px;
    }
    .video-area::after {
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        border: 1px solid #f1f4f8;
        z-index: 2;
        border-radius: 20px;
    }
    .clients-feedback-area .client-description > p {
        font-size: 20px;
    }
    .clients-feedback-area .slick-slide img {
        width: 60px !important;
        height: 60px !important;
    }
    .clients-feedback-area .slick-slide.slick-current img {
        width: 70px !important;
        height: 70px !important;
    }
    .membership-description > h2 {
        font-size: 32px;
    }
    .get-started-button {
        text-align: left;
        margin-top: 20px;
    }
    .contact_from {
        margin-top: 30px;
    }
    .wellcome-heading > h3 {
        font-size: 252px;
        top: -105px;
        left: -1px;
    }
    .wellcome-heading,
    .single-cool-fact {
        margin-bottom: 50px;
    }
    .cool_facts_area .col-12:last-of-type .single-cool-fact {
        margin-bottom: 0;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .wellcome-heading > h3 {
        font-size: 284px;
    }
    .wellcome-heading > h2 {
        font-size: 70px;
    }
    .welcome-thumb {
        bottom: -109px;
        width: 75%;
    }
    .wellcome-heading {
        margin-bottom: 50px;
    }
    .wellcome-heading > h3 {
        top: -139px;
    }
    .get-start-area .email {
        max-width: 370px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .wellcome-heading > h3 {
        font-size: 286px;
    }
    .get-start-area .email {
        margin-bottom: 0;
    }
}