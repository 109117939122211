/* --------------------------
:: Template Name: Colorlib App - App Landing Page 
:: Template Author: Colorlib
:: Template Author URI: https://colorlib.com/
:: Description: Colorlib is a One Page based App Landing Template. It's very clean, creative & beautiful. It's super     responsive & ultra light.
:: Tags: Colorlib, App Landing, Apps, Responsive App Landing, Bootstrap 4, Free Theme, Free HTML Template, HTML5 Template etc.
:: Version: v1.0
:: Created: 11/03/2017
-------------------------- */

/* --- [Master Stylesheet] ----
:: 1.0 Base CSS Start
:: 2.0 Header Area CSS
:: 3.0 Wellcome Area CSS
:: 4.0 Special Area CSS
:: 5.0 Features Area CSS
:: 6.0 Video Area CSS
:: 7.0 Cool Facts Area CSS
:: 8.0 Screenshots Area CSS
:: 9.0 Pricing Plan Area CSS
:: 10.0 Testimonials Area CSS
:: 11.0 CTA Area CSS Start
:: 12.0 Team Area CSS Start
:: 13.0 Contact Area CSS Start
:: 14.0 Footer Area CSS Start
-------------------------- */

@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,700|Montserrat:400,500,700');
/* @import 'css/bootstrap.min.css'; */
@import 'css/animate.css';
@import 'css/magnific-popup.css';
/* @import 'css/owl.carousel.min.css'; */
@import 'css/slick.css';
/* @import 'css/font-awesome.min.css'; */
@import 'css/themify-icons.css';
@import 'css/ionicons.min.css';

/* --------------------------
:: 1.0 Base CSS Start
-------------------------- */

a:hover {
    cursor:pointer;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Cabin', sans-serif;
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cabin', sans-serif;
    font-weight: 400;
    color: #000;
    line-height: 1.2;
}

.section_padding_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section_padding_100_50 {
    padding-top: 100px;
    padding-bottom: 50px;
}

.section_padding_100_70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section_padding_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section_padding_50_20 {
    padding-top: 50px;
    padding-bottom: 20px;
}

.section_padding_150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section_padding_200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.section_padding_0_100 {
    padding-top: 0;
    padding-bottom: 100px;
}

.section_padding_70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section_padding_0_50 {
    padding-top: 0;
    padding-bottom: 50px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

#scrollUp {
    bottom: 0;
    font-size: 24px;
    right: 30px;
    width: 50px;
    background-color: #fb397d;
    color: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

li {
    list-style: none;
}

p {
    color: #726a84;
    font-size: 16px;
    font-weight: 300;
    margin-top: 0;
}

.heading-text > p {
    font-size: 16px;
}

.section-heading > h2 {
    font-weight: 300;
    color: #5b32b4;
    font-size: 48px;
    margin: 0;
}


.section-heading {
    margin-bottom: 60px;
}

.line-shape-white,
.line-shape {
    width: 80px;
    height: 2px;
    background-color: #fb397d;
    margin-top: 15px;
}

.line-shape {
    margin-left: calc(50% - 40px);
}

.table {
    display: table;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

#preloader {
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.colorlib-load {
    -webkit-animation: 2000ms linear 0s normal none infinite running colorlib-load;
    animation: 2000ms linear 0s normal none infinite running colorlib-load;
    background: transparent none repeat scroll 0 0;
    border-color: #dddddd #dddddd #fb397d;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    height: 40px;
    left: calc(50% - 20px);
    position: relative;
    top: calc(50% - 20px);
    width: 40px;
    z-index: 9;
}

@-webkit-keyframes colorlib-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes colorlib-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* --------------------------
:: 2.0 Header Area CSS
-------------------------- */

.header_area {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0;
    padding: 0 4%;
}

.menu_area .navbar-brand {
    font-size: 72px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 1;
    padding: 0;
}


.menu_area .navbar-brand:hover,
.menu_area .navbar-brand:focus {
    color: #fff;
}


.menu_area {
    position: relative;
    z-index: 2;
}

.menu_area #nav .nav-link {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 35px 15px;
}


.menu_area nav ul li > a:hover {
    color: #fb397d;
}

.sing-up-button {
    text-align: right;
}

.sing-up-button > a {
    color: #fff;
    font-weight: 500;
    display: inline-block;
    border: 2px solid #a883e9;
    height: 50px;
    min-width: 178px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px 24px 24px 0px;
}

.sing-up-button > a:hover {
    background: #fb397d;
    color: #fff;
    border-color: transparent;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

/* stcicky css */

.header_area.sticky {
    background-color: #5b32b4;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 99;
}

.header_area.sticky .menu_area .navbar-brand {
    font-size: 50px;
}

.header_area.sticky .menu_area #nav .nav-link {
    padding: 23px 15px;
}
.header_area.sticky .navbar {
    padding: 0;
}


/* --------------------------
:: 3.0 Wellcome Area CSS
-------------------------- */

.wellcome_area {
    background-image: url(img/bg-img/welcome-bg.png);
    height: 900px;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
}

.wellcome_area:after {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    background-color: #fff;
    content: '';
    bottom: 0;
    z-index: 2;
}

.welcome-thumb {
    width: 45%;
    position: absolute;
    bottom: -130px;
    right: 85px;
    z-index: 9;
}

.welcome-thumb img {
    height: auto;
    width: 100%;
}

.wellcome-heading > h2 {
    font-size: 100px;
    color: #ffffff;
    font-weight: 700;
    position: relative;
    z-index: 3;
}

.wellcome-heading > h5 {
    color: #ffffff;
    position: relative;
    z-index: 3;
}

.get-start-area .email {
    background: #9572e8;
    height: 50px;
    max-width: 260px;
    border: none;
    border-radius: 24px;
    padding: 0px 15px;
}

.form-control::-webkit-input-placeholder {
    color: #cec1f4;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #cec1f4;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #cec1f4;
    opacity: 1;
}

.form-control::placeholder {
    color: #cec1f4;
    opacity: 1;
}

.get-start-area .email:focus {
    border: none;
    outline-offset: transparent !important;
    border-radius: 30px;
}

.get-start-area .submit {
    background-color: #fb397d;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    border: none;
    height: 50px;
    min-width: 167px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px 24px 24px 0px;
    margin-left: 10px;
}

.get-start-area .submit:hover {
    background: #6f52e5;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.wellcome-heading > p {
    color: #fff;
}

.wellcome-heading > h3 {
    font-size: 332px;
    position: absolute;
    top: -134px;
    font-weight: 900;
    left: -12px;
    z-index: -1;
    color: #fff;
    opacity: .1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.wellcome-heading {
    margin-bottom: 100px;
}

/* --------------------------
:: 4.0 Special Area CSS
-------------------------- */

.single-icon > i {
    font-size: 36px;
    color: #fb397d;
}

.single-special > h4 {
    font-size: 22px;
    color: #5b32b4;
}

.single-icon {
    margin-bottom: 25px;
}

.single-special > h4 {
    font-size: 22px;
    color: #5b32b4;
    margin-bottom: 15px;
}

.single-special {
    border: 1px solid #eff2f6;
    padding: 40px;
    border-radius: 40px 40px 40px 0px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    margin-bottom: 30px;
}

.special_description_content > h2 {
    color: #5b32b4;
    font-size: 48px;
    margin-bottom: 30px;
}

.single-special:hover {
    -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
            box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
}

.single-special p {
    margin-bottom: 0;
}

.app-download-area {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
    margin-top: 50px;
}

.app-download-btn a {
    border: 1px solid #ddd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 30px;
    border-radius: 30px 30px 30px 0;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.app-download-btn.active a,
.app-download-btn a:hover {
    border-color: transparent;
    background-color: #fb397d;
}

.app-download-btn a i {
    color: #47425d;
    font-size: 24px;
    margin-right: 15px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.app-download-btn a p {
    font-size: 16px;
    line-height: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.app-download-btn a p span {
    font-size: 12px;
    display: block;
}

.app-download-btn:first-child {
    margin-right: 20px;
}

.app-download-btn a:hover i,
.app-download-btn.active a i,
.app-download-btn.active a p,
.app-download-btn a:hover p {
    color: #fff;
}

/* --------------------------
:: 5.0 Features Area CSS
-------------------------- */

.single-feature {
    margin-bottom: 50px;
}

.single-feature > p {
    margin-bottom: 0;
}

.single-feature > i {
    color: #fb397d;
    font-size: 30px;
    display: inline-block;
    float: left;
    margin-right: 10px;
}

.single-feature > h5 {
    font-size: 22px;
    color: #5b32b4;
}

.single-feature > p {
    margin-top: 15px;
}

/* --------------------------
:: 6.0 Video Area CSS
-------------------------- */

.video-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    border: 20px solid #fff;
    border-radius: 20px;
    height: 650px;
    -webkit-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
}

.video-area:after {
    position: absolute;
    content: "";
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    border: 1px solid #f1f4f8;
    z-index: 2;
    border-radius: 20px;
}


.video-area .video-play-btn a {
    width: 80px;
    height: 80px;
    background-color: #fb397d;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 36px;
    line-height: 80px;
    padding-left: 5px;
    border-radius: 50%;
    top: 50%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
}

.video-area .video-play-btn a:hover {
    background-color: #a865e1;
    color: #fff;
}

/* --------------------------
:: 7.0 Cool Facts Area CSS
-------------------------- */

.cool_facts_area {
    position: relative;
    z-index: auto;
    background: #a865e1;
    background: -webkit-linear-gradient(to left, #7873ed, #a865e1);
    background: -webkit-linear-gradient(right, #7873ed, #a865e1);
    background: -webkit-gradient(linear, right top, left top, from(#7873ed), to(#a865e1));
    background: -o-linear-gradient(right, #7873ed, #a865e1);
    background: linear-gradient(to left, #7873ed, #a865e1);
    padding: 180px 0 80px 0;
    top: -100px;
}


.cool_facts_area:after {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
    content: ''
}

.cool-facts-content > i {
    color: #ffffff;
    font-size: 36px;
}

.counter-area {
    min-width: 100px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.counter-area > h3 {
    color: #ffffff;
    font-size: 60px;
    margin-bottom: 0;
    line-height: 1;
}

.cool-facts-content > p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0;
}

/* --------------------------
:: 8.0 Screenshots Area CSS
-------------------------- */

.app_screenshots_slides {
    padding: 0 6%;
}

.app_screenshots_slides .single-shot {
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85)
}

.app_screenshots_slides .center .single-shot {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.app-screenshots-area .owl-dot {
    height: 11px;
    width: 11px;
    border: 2px solid #a49fba;
    border-radius: 50%;
    display: inline-block;
    margin: 0 6px;
    margin-top: 50px;
}

.app-screenshots-area .owl-dot.active {
    background-color: #fb397d;
    border: transparent;
}

.app-screenshots-area .owl-dots {
    height: 100%;
    width: 100%;
    text-align: center;
}

/* --------------------------
:: 9.0 Pricing Plan Area CSS
-------------------------- */

.pricing-plane-area {
    background: #f5f9fc;
}

.single-price-plan {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 30px 30px 30px 0;
    margin-bottom: 30px;
}

.single-price-plan .package-plan {
    padding: 40px 0;
    border-radius: 30px 30px 0 0;
}

.single-price-plan .package-plan > h5 {
    color: #5b32b4;
    font-size: 18px;
}


.single-price-plan .package-plan .ca-price h4 {
    color: #fb397d;
    font-size: 48px;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    line-height: 1;
}

.single-price-plan .package-plan .ca-price span {
    font-size: 18px;
    color: #fb397d;
}

.single-price-plan .plan-button > a {
    background: #726a84;
    padding: 10px 0;
    border-bottom-right-radius: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    display: block;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-price-plan .package-description > p {
    margin-bottom: 0;
    line-height: 1;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;
}

.single-price-plan .package-description > p:first-child {
    border-top: 1px solid #ebebeb;
}

.single-price-plan.active .package-plan,
.single-price-plan.active .plan-button > a,
.single-price-plan:hover .plan-button > a {
    background-color: #a865e1;
}

.single-price-plan.active .package-plan .ca-price > h4,
.single-price-plan.active .package-plan .ca-price > span,
.single-price-plan.active .package-plan > h5,
.single-price-plan.active .plan-button > a {
    color: #fff;
}

/* --------------------------
:: 10.0 Testimonials Area CSS
-------------------------- */

.clients-feedback-area .client-name > h5 {
    color: #5b32b4;
    font-size: 22px;
    margin-top: 5px;
}

.clients-feedback-area .star-icon > i {
    font-size: 18px;
    color: #f1b922;
}

.clients-feedback-area .client-description > p {
    max-width: 1010px;
    margin: auto;
    margin-bottom: 15px;
    font-size: 22px;
}

.clients-feedback-area .client > i {
    font-size: 48px;
    color: #897ceb;
    margin-bottom: 30px;
}

.clients-feedback-area .client-name > p {
    color: #b5aec4;
}

.clients-feedback-area .slick-dots {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.clients-feedback-area .slick-dots li button {
    width: 12px;
    height: 12px;
    text-indent: -999999999999999999999999px;
    border: 3px solid #a49fba;
    background-color: #fff;
    border-radius: 50%;
    margin: 5.5px;
    outline: none;
}

.clients-feedback-area .slick-dots li.slick-active button {
    background-color: #fb397d;
    border-color: #fb397d;
}

.clients-feedback-area .slick-slide.client-thumbnail {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 129px;
}


.clients-feedback-area .slick-slide img {
    display: block;
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.clients-feedback-area .slick-slide.slick-current img {
    width: 100px !important;
    height: 100px !important;
}

.clients-feedback-area .slick-arrow {
    position: absolute;
    width: 30px;
    background-color: #fff;
    height: 100%;
    text-align: center;
    font-size: 24px;
    color: #b5aec4;
    display: block;
    line-height: 550%;
    left: 0;
    z-index: 90;
    cursor: pointer;
}

.clients-feedback-area .slick-arrow.fa-angle-right {
    left: auto;
    right: 0;
    top: 0;
}

/* --------------------------
:: 11.0 CTA Area CSS Start
-------------------------- */

.our-monthly-membership {
    background: #a865e1;
    background: -webkit-linear-gradient(to left, #7873ed, #a865e1);
    background: -webkit-linear-gradient(right, #7873ed, #a865e1);
    background: -webkit-gradient(linear, right top, left top, from(#7873ed), to(#a865e1));
    background: -o-linear-gradient(right, #7873ed, #a865e1);
    background: linear-gradient(to left, #7873ed, #a865e1);
}

.membership-description > h2 {
    color: #ffffff;
    font-size: 36px;
}

.membership-description > p {
    color: #ffffff;
    margin-bottom: 0;
}

.get-started-button > a {
    background: #ffffff;
    height: 45px;
    min-width: 155px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    color: #fb397d;
    font-weight: 500;
    border-radius: 23px 23px 23px 0;
}

.get-started-button > a:hover {
    background: #5b32b4;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.get-started-button {
    text-align: right;
}

/* --------------------------
:: 12.0 Team Area CSS Start
-------------------------- */

.member-image {
    margin-bottom: 30px;
    border-radius: 30px 30px 30px 0;
    position: relative;
    z-index: 1;
}

.member-image img {
    border-radius: 30px 30px 30px 0;
}

.member-text > h4 {
    font-size: 22px;
    color: #5b32b4;
}

.member-text > p {
    color: #b5aec4;
    margin-bottom: 0;
}

.team-hover-effects {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(105, 75, 228, 0.8);
    border-radius: 30px 30px 30px 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
}

.single-team-member:hover .team-hover-effects {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.team-social-icon {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.team-social-icon > a {
    font-size: 14px;
    color: #fff;
    background: #9a81d7;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px 10px 10px 0;
    display: block;
    margin: 2.5px;
}

.single-team-member {
    margin-bottom: 50px;
}

/* --------------------------
:: 13.0 Contact Area CSS Start
-------------------------- */

.address-text > p,
.phone-text > p,
.email-text > p {
    font-size: 18px;
    margin-bottom: 10px;
}

.address-text > p > span,
.phone-text > p > span,
.email-text > p > span {
    color: #5b32b4;
}

.footer-contact-area .line-shape {
    margin-left: 0;
}

.footer-contact-area .heading-text > p {
    margin-top: 50px;
}

.footer-contact-area .heading-text {
    margin-bottom: 50px;
}

#name {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 25px 25px 25px 0;
}

#email {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 25px 25px 25px 0;
}

#message {
    border-radius: 25px 25px 25px 0;
}

.btn.submit-btn {
    background: #fb397d;
    height: 48px;
    min-width: 185px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 22px 25px 22px 0;
}

.btn.submit-btn:hover {
    background: #5b32b4;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.footer-contact-area {
    background: #f5f9fc;
}

.footer-text > p {
    margin-bottom: 50px;
}

/* --------------------------
:: 14.0 Footer Area CSS Start
-------------------------- */

.footer-text > h2 {
    color: #5b32b4;
    font-size: 72px;
    font-weight: 700;
}

.footer-social-icon > a > i {
    background: #9b69e4;
    font-size: 14px;
    color: #ffffff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 10px 10px 10px 0px;
    margin-right: 10px;
}

.footer-social-icon > a > i:hover {
    background: #fb397d;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.social-icon {
    margin-bottom: 30px;
}

.footer-text {
    margin-bottom: 15px;
}

.footer-menu ul li {
    display: inline-block;
}

.footer-menu ul li a {
    border-right: 2px solid #726a84;
    display: block;
    padding: 0 7.5px;
    color: #726a84;
}

.footer-social-icon > a .active {
    background: #fb397d;
}

.footer-menu ul li:last-child a {
    border-right: 0 solid #ddd;
}

.footer-social-icon {
    margin-bottom: 30px;
}

.footer-menu {
    margin-bottom: 15px;
}

.copyright-text > p {
    margin-bottom: 0;
}